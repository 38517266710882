<template>
  <div style="height: 100%">
    <div style="height: 100%" ref="scrollable" v-bar>
      <div class="px-4 py-10 billing-wrap">
        <v-card tile class="py-4 ">
          <div class="v-subheader theme--light">
            <h3 class="pb-2">
              You can find bills for your past payments here
            </h3>
          </div>
          <v-data-table
            :loading="loading"
            loading-text="Loading your bills..."
            no-data-text="You haven't done any payments yet!"
            :headers="headers"
            :items="data"
            :items-per-page="5"
          >
            <template v-slot:item.download="{ item }">
              <a @click.prevent="download(item._id, item.receiptNo)" href="#"
                >Download</a
              >
            </template>
          </v-data-table>
        </v-card>
      </div>
    </div>
  </div>
</template>

<script>
import store from "@/store";
import moment from "moment";

const state = store.billing.state;

export default {
  data() {
    return {
      headers: [
        {
          text: "Date Issued",
          value: "date",
          width: 130,
          sort: (a, b) =>
            moment(a, "DD-MM-YYYY") > moment(b, "DD-MM-YYYY") ? 1 : -1
        },
        {
          text: "Bill No",
          sortable: false,
          value: "receiptNo",
          width: 130
        },
        { text: "Description", value: "desc", sortable: false },
        { text: "Plan Validity", value: "validity", sortable: false },
        {
          text: "Payment Method",
          value: "method",
          sortable: false,
          width: 130
        },
        {
          text: "Amount Paid",
          value: "amount",
          sortable: false,
          width: 130
        },
        {
          text: "",
          value: "download",
          sortable: false,
          width: 130,
          align: "end"
        }
      ]
    };
  },
  mounted() {
    if (!state.loadedOnce) {
      state.refresh();
    }
  },
  methods: { download: (_id, receiptNo) => state.download(_id, receiptNo) },
  computed: {
    loading() {
      return state.loading;
    },
    data() {
      return state.data;
    }
  }
};
</script>

<style lang="scss" scoped>
.billing-wrap {
  box-sizing: border-box !important;
}
</style>
